import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as FileView } from "../../assets/file-view.svg";
import { ReactComponent as TrashFile } from "../../assets/trash.svg";
import { deleteDiary } from "../../api/autenticoAPI";
import { AlertDialog } from "../../components/AlertDialog";
import { CustomAlert } from "../CustomAlert/CustomAlert";

const Container = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  height: 50px;
  margin: 5px auto;
  border: 1px solid #d9d9d9;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const ClassroomName = styled.span`
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
  @media screen and (min-width: 768px) {
    text-align: left;
  }
`;

const ButtonsArea = styled.div`
  a {
    margin-right: 15px;
  }
`;

export const ItemDiaryStats = ({ class_name, url, id, updateDiariesList }) => {
  const [open, setOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const handleDelete = async () => {
    try {
      await deleteDiary(id);
      setInfoMessage("Agenda excluída com sucesso!");
      setShowMessage(true);
      updateDiariesList();
    } catch (error) {
      console.log(error);
      setInfoMessage("Erro ao excluir a agenda!");
      setShowMessage(true);
    }
  };
  return (
    <Container>
      <ClassroomName>{class_name}</ClassroomName>
      <ButtonsArea>
        <a href={url} rel="noopener noreferrer" target="_blank">
          <FileView width={25} height={25} />
        </a>
        <a href="#delete" onClick={() => setOpen(true)}>
          <TrashFile width={25} height={25} />
        </a>
      </ButtonsArea>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title="Confirmação de exclusão de agenda!"
        body={"Você tem certeza que deseja excluir essa agenda?"}
        handleConfirm={() => handleDelete()}
      />
      <CustomAlert
        infoType="success"
        status={showMessage}
        setStatus={setShowMessage}
        infoMessage={infoMessage}
      />
    </Container>
  );
};
