import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 20px 0;
`;
export const SelectDate = ({ date, setDate }) => {
  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pt-br"}>
        <DatePicker
          views={["day"]}
          label="Selecione a data"
          value={date}
          onChange={(newValue) => {
            setDate(new Date(newValue));
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
      </LocalizationProvider>
    </Container>
  );
};
