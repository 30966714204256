import React from "react";
import { Container, ContentArea, PageTitle, TitleArea } from "./styles";
import Header from "../../components/Header";
import IconsBar from "../../components/IconsBar/IconsBar";
import { RegisterTabs } from "../../components/RegisterTabs/RegisterTabs";

export const Registration = () => {
  return (
    <Container>
      <Header />
      <IconsBar />
      <TitleArea>
        <PageTitle>Registro de Matrículas</PageTitle>
      </TitleArea>
      <ContentArea>
        <RegisterTabs />
      </ContentArea>
    </Container>
  );
};
