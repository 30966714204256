import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getCaptions } from "../api/autenticoAPI";
import EditableText from "./EditableText";
import { Checkbox } from "@mui/material";
import { AuthContext } from "../contexts/Auth/AuthContext";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const NameTitle = styled.span`
  padding-left: 10px;
  width: 400px;
  font-weight: bold;
`;
const CommonTitle = styled.span`
  text-align: center;
  width: 60px;
  font-weight: bold;
`;

export const TitleLegend = ({ TurmaID, period, selectAll, setSelectAll }) => {
  const { user } = React.useContext(AuthContext);
  const [dbCaptions, setDbCaptions] = useState({
    TurmaID,
    period,
    at1: "AT1",
    at2: "AT2",
    at3: "AT3",
    at4: "AT4",
    at5: "AT5",
    at6: "AT6",
  });
  const [loading, setLoading] = useState(true);
  const readOnly = !["admin", "coordinator"].includes(user.level);

  useEffect(() => {
    getCaptions(TurmaID, period).then((res) => {
      if (res) {
        setDbCaptions(res);
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      {!loading && (
        <Container>
          <Checkbox
            checked={selectAll}
            onChange={() => setSelectAll(!selectAll)}
          />
          <NameTitle>Alunos</NameTitle>
          <EditableText
            controller={"at1"}
            defaultTitle={dbCaptions.at1}
            dbCaptions={dbCaptions}
            setDbCaptions={setDbCaptions}
            readOnly={readOnly}
          />
          <EditableText
            controller={"at2"}
            defaultTitle={dbCaptions.at2}
            dbCaptions={dbCaptions}
            setDbCaptions={setDbCaptions}
            readOnly={readOnly}
          />
          <EditableText
            controller={"at3"}
            defaultTitle={dbCaptions.at3}
            dbCaptions={dbCaptions}
            setDbCaptions={setDbCaptions}
            readOnly={readOnly}
          />
          <EditableText
            controller={"at4"}
            defaultTitle={dbCaptions.at4}
            dbCaptions={dbCaptions}
            setDbCaptions={setDbCaptions}
            readOnly={readOnly}
          />
          <EditableText
            controller={"at5"}
            defaultTitle={dbCaptions.at5}
            dbCaptions={dbCaptions}
            setDbCaptions={setDbCaptions}
            readOnly={readOnly}
          />
          <EditableText
            controller={"at6"}
            defaultTitle={dbCaptions.at6}
            dbCaptions={dbCaptions}
            setDbCaptions={setDbCaptions}
            readOnly={readOnly}
          />
          <CommonTitle>AC1</CommonTitle>
          <CommonTitle>AC2</CommonTitle>
          <CommonTitle>Média</CommonTitle>
          <CommonTitle>RB</CommonTitle>
          <CommonTitle>MPR</CommonTitle>
        </Container>
      )}
    </>
  );
};
