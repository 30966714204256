import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import IconsBar from "../components/IconsBar/IconsBar";
import styled from "styled-components";
import ActivityDiary from "../components/ActivityDiary";
import CardClassroom from "../components/CardClassroom";
import { getInfoTeacher } from "../api/autenticoAPI";
import { AuthContext } from "../contexts/Auth/AuthContext";

const Container = styled.div`
  text-align: center;
`;
const ContentArea = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
`;

const Diary = () => {
  const { user } = useContext(AuthContext);
  const [selected, setSelected] = useState({
    classroom: { name: "8" },
    subject: { name: "quimica" },
  });
  const [open, setOpen] = useState(false);
  const [classrooms, setClassrooms] = useState([]);
  useEffect(() => {
    getInfoTeacher(user.id).then((res) => setClassrooms(res));
  }, []);
  useEffect(() => {
    // console.log(selected);
  }, [selected]);
  return (
    <Container>
      <Header />
      <IconsBar />
      <ActivityDiary setOpen={setOpen} open={open} selected={selected} />
      <ContentArea>
        {classrooms.map((classroom, index) => (
          <CardClassroom
            key={index}
            setSelected={setSelected}
            setOpen={setOpen}
            title={classroom}
            subjects={classroom.classes_subjects}
          />
        ))}
      </ContentArea>
    </Container>
  );
};

export default Diary;
