import React from "react";
import { Alert, Snackbar } from "@mui/material";

export const AlertMessages = ({
  show,
  typeMessage,
  contentMessage,
  setShow,
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShow(false);
  };

  return (
    <Snackbar
      open={show}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={typeMessage}
        sx={{ width: "100%" }}
      >
        {contentMessage}
      </Alert>
    </Snackbar>
  );
};
