import styled from "styled-components";

export const Container = styled.div`
  margin: 20px auto;
  padding: 20px;
  width: 70%;
  font-size: 18px;
  ul {
    padding: 20px;
  }
`;
export const VersionInfo = styled.span`
  font-weight: bold;
  span {
    font-weight: normal;
    font-size: 15px;
  }
`;
