import styled from "styled-components";
import { SelectDate } from "../SelectDate";
import React from "react";
import { ItemDiaryStats } from "./ItemDiaryStats";
import { useEffect } from "react";
import { getDiariesByDate } from "../../api/autenticoAPI";
import moment from "moment";
import { useState } from "react";

const Container = styled.div``;

export const Diaries = ({ date, setDate, focused }) => {
  const [diariesList, setDiariesList] = useState([]);
  const updateDiariesList = () => {
    getDiariesByDate(moment(new Date(date)).format("YYYY-MM-DD")).then((res) =>
      setDiariesList(res.diaries)
    );
  };
  useEffect(() => {
    updateDiariesList();
  }, [date, focused]);
  return (
    <Container>
      <SelectDate date={date} setDate={setDate} />
      {diariesList.length > 0 &&
        diariesList.map((diary, index) => (
          <ItemDiaryStats
            key={index}
            class_name={diary.class_name}
            url={diary.url}
            id={diary.id}
            updateDiariesList={updateDiariesList}
          />
        ))}
    </Container>
  );
};
