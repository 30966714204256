import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  ContentArea,
  StudentsList,
  TitlePage,
  SelectionArea,
} from "./styles";
import IconsBar from "../../components/IconsBar/IconsBar";
import Header from "../../components/Header";
import { SelectDate } from "../../components/SelectDate";
import { IntegranteItem } from "../../components/IntegranteItem/IntegranteItem";
import { getStudentsByClassroom } from "../../api/sponteAPI";
import { getIntegrateClasses } from "../../utils/YEARCONSTS";
import { ClassroomSelect } from "../../components/ClassroomSelect";
import { getRoutinesByDate } from "../../api/autenticoAPI";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { Loading } from "../../components/Loading/Loading";

export const Integrante = () => {
  const [date, setDate] = useState(new Date());
  const [students, setStudents] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [partialData, setPartialData] = useState([]);
  const [upadateList, setUpdateList] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = useContext(AuthContext);

  const updateRoutineList = () => {
    if (classrooms.length > 0) {
      setLoading(true);
      getStudentsByClassroom(classrooms[1]).then((res) => {
        if (res) {
          setStudents(res.Integrantes);
          setLoading(false);
        }
      });
    }
    getRoutinesByDate(date.toISOString().split("T")[0]).then((res) =>
      setPartialData(res)
    );
  };

  useEffect(() => {
    updateRoutineList();
  }, [classrooms, date, upadateList]);

  return (
    <Container>
      <Header />
      <IconsBar />
      <ContentArea>
        <TitlePage>Rotinas do Integral</TitlePage>
        <SelectionArea>
          <SelectDate date={date} setDate={setDate} />
          <ClassroomSelect
            classrooms={classrooms}
            setClassrooms={setClassrooms}
            integrante={getIntegrateClasses(auth.user.unit)}
          />
        </SelectionArea>
        {loading && <Loading />}
        {classrooms.length > 0 && (
          <StudentsList>
            {students.length > 0 &&
              students.map((item) => (
                <IntegranteItem
                  key={`student_${item.AlunoID}`}
                  student={item}
                  status={partialData.some(
                    (student) => student.idSponte === item.AlunoID
                  )}
                  date={date}
                  editData={
                    partialData.filter(
                      (student) => student.idSponte === item.AlunoID
                    )[0]
                  }
                  upadateList={upadateList}
                  setUpdateList={setUpdateList}
                />
              ))}
          </StudentsList>
        )}
      </ContentArea>
    </Container>
  );
};
