import React from "react";
import {
  Container,
  DataArea,
  DataGroup,
  DataLabel,
  DataSubtitle,
  DataValue,
} from "./styles";

export const DataInfo = ({ type, user, password }) => {
  return (
    <Container>
      <DataArea>
        <DataSubtitle>{type}</DataSubtitle>
        <DataGroup>
          <DataLabel>Usuário:</DataLabel>
          <DataValue>{user}</DataValue>
        </DataGroup>
        <DataGroup>
          <DataLabel>Senha:</DataLabel>
          <DataValue>{password}</DataValue>
        </DataGroup>
      </DataArea>
    </Container>
  );
};
