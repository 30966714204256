import styled from "styled-components";

export const Container = styled.div``;
export const TitleArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageTitle = styled.span`
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  color: #1e958c;
  margin: 10px auto;
`;

export const ContentArea = styled.div`
  width: 80%;
  margin: 20px auto;
`;
