import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export const SubjectSelect = ({ subject, setSubject, subjects }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSubject(value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 350 }}>
        <InputLabel id="subject-select">Disciplina</InputLabel>
        <Select
          labelId="subject-select"
          value={subject || ""}
          onChange={handleChange}
          input={<OutlinedInput label="Disciplina" />}
          renderValue={(selected) => {
            const selectSubject = subjects.find(
              (p) => p.DisciplinaID === selected.id
            );
            return selectSubject ? selectSubject.Nome : "";
          }}
          MenuProps={MenuProps}
        >
          {subjects &&
            subjects.map((subjectOption) => (
              <MenuItem
                key={subjectOption.DisciplinaID}
                value={{
                  id: subjectOption.DisciplinaID,
                  name: subjectOption.Nome,
                }}
              >
                <Checkbox checked={subject && subjectOption.DisciplinaID === subject.id} />
                <ListItemText primary={subjectOption.Nome} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};
