import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #868383;
  width: 100%;
  margin: 2px auto;
  padding: 5px;
`;
export const NameArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
`;
export const IconsArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  gap: 10px;
`;
export const StudentName = styled.span`
  font-size: 15px;
  font-weight: bold;
`;
export const CardsArea = styled.div`
  @media only screen and (max-width: 600px) {
    min-height: 100vh;
  }

  overflow-y: auto;
  overflow-x: hidden;
`;
export const ButtonsArea = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: center;
  align-items: start;
`;
export const AreaText = styled.div`
  width: 98%;
  margin: auto;
`;
