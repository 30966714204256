import styled from "styled-components";

export const IconsBarArea = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #d9d9d9;
  border-radius: 50px;
  width: 70%;
  margin: 0 auto;
  margin-top: -10px;
  padding: 10px;
  a {
    text-decoration: none;
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
export const LabelIcon = styled.p`
  @media only screen and (max-width: 768px) {
    display: none;
  }
  font-size: 12px;
  font-weight: bold;
  color: #004d93;
`;
