const IntegrantePatos = [
  { Nome: "INTEGRAL 2024 INF (MANHÃ)", TurmaID: 1185 },
  { Nome: "INTEGRAL 2024 INF (TARDE)", TurmaID: 1192 },
  { Nome: "INTEGRAL 2024 FUND (MANHÃ)", TurmaID: 1193 },
  { Nome: "INTEGRAL 2024 FUND (TARDE)", TurmaID: 1186 },
];

const IntegranteCampina = [{ Nome: "INTEGRAL 2024", TurmaID: 1114 }];

export const getIntegrateClasses = (unit) => {
  const integranteTurmas =
    unit === "CAMPINA GRANDE" ? IntegranteCampina : IntegrantePatos;
  return integranteTurmas;
};

export const foods_campina = {
  breakfast: {
    foods: [
      { id: 1, name: "Achocolatado" },
      { id: 2, name: "Banana" },
      { id: 3, name: "Biscoito" },
      { id: 4, name: "Bolo" },
      { id: 5, name: "Cereal" },
      { id: 6, name: "Comprou lanche" },
      { id: 7, name: "Cuscuz com ovo" },
      { id: 8, name: "Cuscuz com carne" },
      { id: 9, name: "Iogurte" },
      { id: 10, name: "Lanche de casa" },
      { id: 11, name: "Maçã" },
      { id: 12, name: "Melancia" },
      { id: 13, name: "Misto quente" },
      { id: 14, name: "Laranja" },
      { id: 15, name: "Ovo" },
      { id: 16, name: "Pão assado" },
      { id: 17, name: "Pão com carne" },
      { id: 18, name: "Pão com queijo" },
      { id: 19, name: "Pão com ovo" },
      { id: 20, name: "Pão com presunto" },
      { id: 21, name: "Pão recheado com frango" },
      { id: 22, name: "Panqueca" },
      { id: 23, name: "Queijo" },
      { id: 24, name: "Salada de frutas" },
      { id: 25, name: "Sorda" },
      { id: 26, name: "Suco da fruta" },
      { id: 27, name: "Tapioca" },
      { id: 28, name: "Uva" },
      { id: 29, name: "Vitamina" },
    ],
  },
  lunch: {
    foods: [
      { id: 1, name: "Abacaxi" },
      { id: 2, name: "Almondegas" },
      { id: 3, name: "Arrumadinho" },
      { id: 4, name: "Arroz" },
      { id: 5, name: "Arroz de Leite" },
      { id: 6, name: "Banana" },
      { id: 7, name: "Batata doce" },
      { id: 8, name: "Carne moída" },
      { id: 9, name: "Carne de panela" },
      { id: 10, name: "Carne assada" },
      { id: 11, name: "Carne desfiada" },
      { id: 12, name: "Cuscuz" },
      { id: 13, name: "Feijão" },
      { id: 14, name: "Farofa" },
      { id: 15, name: "Frango assado" },
      { id: 16, name: "Frango de panela" },
      { id: 17, name: "Frango no molho" },
      { id: 18, name: "Frutas variadas" },
      { id: 19, name: "Legumes" },
      { id: 20, name: "Laranja" },
      { id: 21, name: "Macarrão" },
      { id: 22, name: "Melancia" },
      { id: 23, name: "Milho" },
      { id: 24, name: "Omelete" },
      { id: 25, name: "Ovo" },
      { id: 26, name: "Panqueca" },
      { id: 27, name: "Peixe frito" },
      { id: 28, name: "Purê" },
      { id: 29, name: "Salada de folhas" },
      { id: 30, name: "Salada de legumes" },
      { id: 31, name: "Strogonoff de frango" },
      { id: 32, name: "Suco da fruta" },
      { id: 33, name: "Tomate" },
      { id: 34, name: "Torta de legumes" },
      { id: 35, name: "Uva" },
      { id: 36, name: "Verduras" },
    ],
  },
  snack: {
    foods: [
      { id: 1, name: "Achocolatado" },
      { id: 2, name: "Arroz de leite" },
      { id: 3, name: "Banana" },
      { id: 4, name: "Banana com leite ou achocolatado" },
      { id: 5, name: "Biscoito" },
      { id: 6, name: "Bolo" },
      { id: 7, name: "Bolo de caco" },
      { id: 8, name: "Bolo de cenoura" },
      { id: 9, name: "Cereal" },
      { id: 10, name: "Cookies" },
      { id: 11, name: "Comprou lanche" },
      { id: 12, name: "Creme de galinha" },
      { id: 13, name: "Crepioca" },
      { id: 14, name: "Empada de frango" },
      { id: 15, name: "Frutas variadas" },
      { id: 16, name: "Iogurte" },
      { id: 17, name: "Lanche de casa" },
      { id: 18, name: "Laranja" },
      { id: 19, name: "Maça" },
      { id: 20, name: "Melancia" },
      { id: 21, name: "Misto quente" },
      { id: 22, name: "Ovo" },
      { id: 23, name: "Painço" },
      { id: 24, name: "Panqueca" },
      { id: 25, name: "Pão assado" },
      { id: 26, name: "Pão com carne" },
      { id: 27, name: "Pão com presunto" },
      { id: 28, name: "Pão com ovo" },
      { id: 29, name: "Pão com queijo" },
      { id: 30, name: "Pão de queijo" },
      { id: 31, name: "Pastel de frango" },
      { id: 32, name: "Pizza" },
      { id: 33, name: "Pizza de frango" },
      { id: 34, name: "Sorda" },
      { id: 35, name: "Suco da fruta" },
      { id: 36, name: "Tapioca" },
      { id: 37, name: "Tortinha de frango" },
      { id: 38, name: "Uva" },
      { id: 39, name: "Vitamina de banana" },
    ],
  },
};

export const foods_patos = {
  breakfast: {
    foods: [
      { id: 1, name: "Bolo de chocolate" },
      { id: 2, name: "Bolo de cenoura com calda (opcional)" },
      { id: 3, name: "Brownie" },
      { id: 4, name: "Comprou lanche" },
      { id: 5, name: "Chocolate quente" },
      { id: 6, name: "Cupcake" },
      { id: 7, name: "Cuscuz com ovo" },
      { id: 8, name: "Frutas" },
      { id: 9, name: "Lanche de casa" },
      { id: 10, name: "Pão com carne desfiada" },
      { id: 11, name: "Pão de queijo" },
      { id: 12, name: "Suco" },
      { id: 13, name: "Suco ao leite" },
      { id: 14, name: "Tapioca com queijo" },
      { id: 15, name: "Torta de frango" },
      { id: 16, name: "Vitamina de fruta" },
    ],
  },
  lunch: {
    foods: [
      { id: 1, name: "Almôndegas" },
      { id: 2, name: "Arroz com cenoura e repolho" },
      { id: 3, name: "Arroz molinho" },
      { id: 4, name: "Arroz solto" },
      { id: 5, name: "Carne desfiada ao molho branco" },
      { id: 6, name: "Cubinhos de carne cozido" },
      { id: 7, name: "Cubinhos de frango ao molho branco" },
      { id: 8, name: "Farofa" },
      { id: 9, name: "Feijão de Farofa" },
      { id: 10, name: "Feijão Preto" },
      { id: 11, name: "Feijão simples" },
      { id: 12, name: "Fruta" },
      { id: 13, name: "Macarrão" },
      { id: 14, name: "Purê de batata" },
      { id: 15, name: "Risoto de Frango" },
      { id: 16, name: "Rubacão" },
      { id: 17, name: "Salada cozida" },
      { id: 18, name: "Suco" },
      { id: 19, name: "Suco de Fruta" },
      { id: 20, name: "Tirinhas de Carne" },
    ],
  },
  snack: {
    foods: [
      { id: 1, name: "Bolo de laranja com calda" },
      { id: 2, name: "Brownie de batata doce" },
      { id: 3, name: "Cachorro quente" },
      { id: 4, name: "Comprou lanche" },
      { id: 5, name: "Chocolate quente" },
      { id: 6, name: "Fruta" },
      { id: 7, name: "Lanche de casa" },
      { id: 8, name: "Iogurte de Inhame e morango" },
      { id: 9, name: "Panqueca com recheio de frango cremoso" },
      { id: 10, name: "Panqueca de queijo" },
      { id: 11, name: "Pizza saudável" },
      { id: 12, name: "Salada de Fruta" },
      { id: 13, name: "Suco" },
      { id: 14, name: "Suco de fruta (ao leite)" },
      { id: 15, name: "Tapioca recheada (frango e mista)" },
      { id: 16, name: "Torta de cenoura e frango" },
      { id: 17, name: "Vitamina" },
    ],
  },
};
