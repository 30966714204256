import styled from "styled-components";

export const Container = styled.div`
  border-radius: 10px;
  border: 1px solid #868383;
  margin: 5px auto;
  width: 98%;
`;
export const TitleArea = styled.div`
  align-items: center;
  background-color: #d9d9d9;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`;
export const TitleText = styled.span`
  color: #004d93;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;
export const SelectArea = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
`;
export const SelectItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: ${({ selected }) => (selected ? 1 : 0.2)};
`;
export const TextSelection = styled.span`
  align-items: center;
  color: ${({ color }) => color};
  display: flex;
  font-size: 8px;
  font-weight: bold;
  justify-content: center;
  text-transform: uppercase;
  margin-left: 5px;
`;
export const FoodsArea = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
export const QuestionsArea = styled.div`
  margin: 10px 0;
  align-items: center;
  display: flex;
  justify-content: space-around;
  @media only screen and (max-width: 610px) {
    flex-direction: column;
  }
`;
export const QuestionText = styled.span`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;
export const InfoTitle = styled.span`
  font-size: 12px;
  margin-left: 10px;
`;
