import { IconsBarArea, LabelIcon } from "./styles";
import React, { useContext } from "react";
import { ReactComponent as RegistrationIcon } from "../../assets/register_icon.svg";
import { ReactComponent as DocumentsIcon } from "../../assets/documents.svg";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { ReactComponent as AnnouncementsIcon } from "../../assets/announcements.svg";
import { ReactComponent as BookIcon } from "../../assets/book-open.svg";
import { ReactComponent as GradesIcon } from "../../assets/grades.svg";
import { ReactComponent as IntegranteIcon } from "../../assets/integrante_icon.svg";
import { ReactComponent as StatsIcon } from "../../assets/stats.svg";
import { ReactComponent as ConfigIcon } from "../../assets/config.svg";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth/AuthContext";

const IconsBar = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  return (
    <IconsBarArea>
      {(user && user.level) === "teacher" && (
        <>
          <Link to={"/autentico/teachers/diary"}>
            <CalendarIcon width={25} height={25} />
          </Link>
          <Link to={"/autentico/study"}>
            <BookIcon width={25} height={25} />
          </Link>
        </>
      )}
      {user && ["admin", "secretary"].includes(user.level) && (
        <Link
          to={"/autentico/secretary"}
          style={{
            opacity: location.pathname === "/autentico/secretary" ? 1 : 0.4,
          }}
        >
          <DocumentsIcon width={25} height={25} />
          <LabelIcon>Documentos</LabelIcon>
        </Link>
      )}
      {user &&
        ["admin", "assistant", "coordinator", "secretary"].includes(
          user.level
        ) && (
          <>
            <Link
              to={"/autentico/registration"}
              style={{
                opacity:
                  location.pathname === "/autentico/registration" ? 1 : 0.4,
              }}
            >
              <RegistrationIcon width={25} height={25} />
              <LabelIcon>Matrículas</LabelIcon>
            </Link>

            {/* <Link
            to={"/autentico/notifications"}
            style={{
              opacity:
                location.pathname === "/autentico/notifications" ? 1 : 0.4,
            }}
          >
            <NotificationIcon width={25} height={25} />
            <LabelIcon>Recados</LabelIcon>
          </Link> */}

            <Link
              to={"/autentico/announcements"}
              style={{
                opacity:
                  location.pathname === "/autentico/announcements" ? 1 : 0.4,
              }}
            >
              <AnnouncementsIcon width={25} height={25} />
              <LabelIcon>Comunicados</LabelIcon>
            </Link>

            <Link
              to={"/autentico/diary"}
              style={{
                opacity: location.pathname === "/autentico/diary" ? 1 : 0.4,
              }}
            >
              <CalendarIcon width={25} height={25} />
              <LabelIcon>Agendas</LabelIcon>
            </Link>
            <Link
              to={"/autentico/integrante"}
              style={{
                opacity:
                  location.pathname === "/autentico/integrante" ? 1 : 0.4,
              }}
            >
              <IntegranteIcon width={25} height={25} />
              <LabelIcon>Integral</LabelIcon>
            </Link>
            <Link
              to={"/autentico/grades"}
              style={{
                opacity: location.pathname === "/autentico/grades" ? 1 : 0.4,
              }}
            >
              <GradesIcon width={45} height={25} />
              <LabelIcon>Avaliações</LabelIcon>
            </Link>

            <Link
              to={"/autentico/stats"}
              style={{
                opacity: location.pathname === "/autentico/stats" ? 1 : 0.4,
              }}
            >
              <StatsIcon width={25} height={25} />
              <LabelIcon>Estatísticas</LabelIcon>
            </Link>
            {user && ["admin"].includes(user.level) && (
              <Link
                to={"/autentico/config"}
                style={{
                  opacity: location.pathname === "/autentico/config" ? 1 : 0.4,
                }}
              >
                <ConfigIcon width={25} height={25} />
                <LabelIcon>Configurações</LabelIcon>
              </Link>
            )}
          </>
        )}
    </IconsBarArea>
  );
};

export default IconsBar;
