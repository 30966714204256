import styled from "styled-components";

export const StudentsGrades = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
`;

export const ListStudents = styled.div`
  gap: 10px;
`;

export const InputName = styled.input`
  border: 2px solid #868383;
  padding: 8px;
  min-width: 400px;
`;

export const InputGradeAC1 = styled.input`
  padding: 8px;
  width: 60px;
  border: 2px solid #ab0505;
  text-align: center;
`;
export const InputGradeAC2 = styled.input`
  padding: 8px;
  width: 60px;
  border: 2px solid #004d93;
  text-align: center;
`;
export const InputMediaAC1 = styled.input`
  padding: 8px;
  width: 60px;
  border: 2px solid #ab0505;
  background-color: #d3d3d3;
  text-align: center;
`;
export const InputMediaAC2 = styled.input`
  padding: 8px;
  width: 60px;
  border: 2px solid #004d93;
  background-color: #d3d3d3;
  text-align: center;
`;
export const InputMB = styled.input`
  padding: 8px;
  width: 60px;
  border: 2px solid #1e958c;
  background-color: #d3d3d3;
  text-align: center;
`;
export const InputRM = styled.input`
  padding: 8px;
  width: 60px;
  border: 2px solid #ebbb10;
  text-align: center;
`;
export const InputMPR = styled.input`
  padding: 8px;
  width: 60px;
  border: 2px solid #000000;
  background-color: #d3d3d3;
  text-align: center;
`;
