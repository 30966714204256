import * as React from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Form, PageCaption, VersionCode } from "./styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Logo from "../../assets/logo-large.png";
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
} from "@mui/material";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import packageJs from "../../../package.json";

const FormSignIn = () => {
  const auth = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [values, setValues] = React.useState({
    login: "",
    password: "",
    type: "",
    showPassword: false,
    unit: "CAMPINA GRANDE",
  });
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (values.login && values.password) {
      const isLogged = await auth.signin(
        values.login,
        values.password,
        values.type,
        values.unit
      );
      if (isLogged) {
        navigate("/autentico/home");
      } else {
        setErrorMessage(
          "Acesso negado ao sistema! Verifique o usuário e ou a senha."
        );
        setError(true);
      }
    } else {
      setErrorMessage("Por favor, preencha os campos de login e senha!");
      setError(true);
    }
  };
  return (
    <Form>
      <img src={Logo} alt="Logo" />
      <PageCaption>Acesso ao sistema</PageCaption>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Unidade</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={values.unit}
          onChange={handleChange("unit")}
          label="Unidade"
        >
          <MenuItem value={"CAMPINA GRANDE"}>CAMPINA GRANDE</MenuItem>
          <MenuItem value={"PATOS"}>PATOS</MenuItem>
        </Select>
      </FormControl>

      <TextField
        label="Usuário"
        variant="outlined"
        value={values.login}
        onChange={handleChange("login")}
      />
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={values.showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange("password")}
          onKeyDown={handleKeyPress}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Senha"
        />
      </FormControl>
      <VersionCode> Versão: {packageJs.version} </VersionCode>
      <Button variant="contained" onClick={handleLogin}>
        Entrar
      </Button>
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Form>
  );
};

export default FormSignIn;
