import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container, VersionInfo } from "./styles";

export default function News() {
  return (
    <Container>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <VersionInfo>
            Versão 4.5.0 <span>07/09/2024</span>
          </VersionInfo>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Adiciona a seção de envio de documentos</li>
            <li>Adiciona a seção de consulta de documentos</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <VersionInfo>
            Versão 4.4.0 <span>02/08/2024</span>
          </VersionInfo>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Altera o cardápio da unidade Patos</li>
            <li>Torna independente os cardápios de acordo com a unidade</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <VersionInfo>
            Versão 4.3.1 <span>02/07/2024</span>
          </VersionInfo>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Adicionado função de cadastro de professores</li>
            <li>Adicionado função de cadastro de usuários ( somente admin )</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <VersionInfo>
            Versão 4.3.0 <span>19/06/2024</span>
          </VersionInfo>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              Adicionado botão "VERIFICAR MÉDIAS" cuja função é ilustrar
              inconsistências entre o aplicativo e o Sponte.
            </li>
            <li>
              Melhoria no calculo das médias, arredondando os valores decimais.
            </li>
            <li>
              Verificação automática de falhas no calculo das médias bimestrais.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <VersionInfo>
            Versão 4.2.0 <span>09/06/2024</span>
          </VersionInfo>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              Adição de cores para representar a situação do aluno em cada
              disciplina.
            </li>
            <li>
              Implementação de função para repetição automática de notas de
              avaliações.
            </li>
            <li>
              Inclusão de botão para cálculo automático da média bimestral.
            </li>
            <li>
              Adição de função para formatação de números inteiros com casas
              decimais.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <VersionInfo>
            Versão 4.1.1 <span>02/05/2024</span>
          </VersionInfo>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Exibição do controle de versões</li>
            <li>Correção na exibição dos links e anexos nos comunicados</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <VersionInfo>
            Versão 4.1.0 <span>02/04/2024</span>
          </VersionInfo>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>Excluir agendas</li>
            <li>Excluir comunicados</li>
            <li>Menu estatísticas melhorado</li>
            <li>Aumento da capacidade de envio de imagens mais pesadas</li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}
