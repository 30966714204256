import React from "react";
import { Routes, Route } from "react-router-dom";
import { RequireAuth } from "./contexts/Auth/RequireAuth";
import Announcements from "./pages/Announcements/Announcements";
import GraphDiary from "./pages/GraphDiary";
import TeacherDiary from "./pages/TeacherDiary";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Stats from "./pages/Stats";
import Study from "./pages/Study";
import Grades from "./pages/Grades/Grades";
import Configure from "./pages/Configure/Configure";
import { Registration } from "./pages/Registration/Registration";
import { Integrante } from "./pages/Integrante/Integrante";
import Secretary from "./pages/Secretary/Secretary";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/autentico/registration"
        element={
          <RequireAuth
            permition={["admin", "assistant", "coordinator", "secretary"]}
          >
            <Registration />
          </RequireAuth>
        }
      />

      <Route
        path="/autentico/secretary"
        element={
          <RequireAuth permition={["admin", "secretary"]}>
            <Secretary />
          </RequireAuth>
        }
      />
      <Route
        path="/autentico/teachers/diary"
        element={
          <RequireAuth permition={["admin", "teacher"]}>
            <TeacherDiary />
          </RequireAuth>
        }
      />
      <Route
        path="/autentico/diary"
        element={
          <RequireAuth
            permition={["admin", "assistant", "coordinator", "secretary"]}
          >
            <GraphDiary />
          </RequireAuth>
        }
      />
      <Route
        path="/autentico/integrante"
        element={
          <RequireAuth
            permition={["admin", "assistant", "coordinator", "secretary"]}
          >
            <Integrante />
          </RequireAuth>
        }
      />
      <Route
        path="/autentico/announcements"
        element={
          <RequireAuth
            permition={["admin", "assistant", "coordinator", "secretary"]}
          >
            <Announcements />
          </RequireAuth>
        }
      />
      <Route
        path="/autentico/study"
        element={
          <RequireAuth permition={["admin", "teacher"]}>
            <Study />
          </RequireAuth>
        }
      />
      <Route
        path="/autentico/grades"
        element={
          <RequireAuth
            permition={[
              "admin",
              "assistant",
              "coordinator",
              "teacher",
              "secretary",
            ]}
          >
            <Grades />
          </RequireAuth>
        }
      />
      <Route
        path="/autentico/stats"
        element={
          <RequireAuth
            permition={["admin", "assistant", "coordinator", "secretary"]}
          >
            <Stats />
          </RequireAuth>
        }
      />
      <Route
        path="/autentico/config"
        element={
          <RequireAuth permition={["admin"]}>
            <Configure />
          </RequireAuth>
        }
      />
      <Route
        path="/autentico/"
        element={
          <RequireAuth permition="all">
            <Login />
          </RequireAuth>
        }
      />
      <Route
        path="/autentico/home"
        element={
          <RequireAuth permition="all">
            <Home />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default AllRoutes;
