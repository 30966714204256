import { CircularProgress } from "@mui/material";
import { Container } from "./styles";
import React from "react";
import { ReactComponent as LogoIcon } from "../../assets/a-logo.svg";

export const Loading = () => {
  return (
    <Container>
      <LogoIcon width={300} height={100} />
      <CircularProgress />
    </Container>
  );
};
