import React, { useContext } from "react";
import { Container, InputsArea } from "./styles";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { setUserAccess } from "../../api/autenticoAPI";
import { CustomAlert } from "../CustomAlert/CustomAlert";

const itens = [
  { level: "assistant", label: "AUXILIAR" },
  { level: "coordinator", label: "COORDENADOR" },
  { level: "director", label: "DIRETOR" },
];

export function UserRegistration({ teacher }) {
  const auth = useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [level, setLevel] = useState(teacher ? "teacher" : "");
  const [infoType, setInfoType] = useState("success");
  const [status, setStatus] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const resetStates = () => {
    setName("");
    setEmail("");
    setLogin("");
    setPassword("");
    setLevel(teacher ? "teacher" : "");
  };

  const handleRegister = async () => {
    const newUser = {
      name,
      email,
      password,
      level,
      login,
      unit: auth.user.unit,
    };
    const response = await setUserAccess(newUser);
    if (response.status) {
      setStatus(true);
      setInfoType("success");
      setInfoMessage(response.message);
      resetStates();
    } else {
      setStatus(true);
      setInfoType("error");
      setInfoMessage(response.message);
    }
  };
  return (
    <Container>
      <InputsArea>
        <TextField
          value={name}
          onChange={({ target }) => setName(target.value)}
          label="Nome"
          variant="outlined"
          fullWidth
        />
        <TextField
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          label="Email"
          variant="outlined"
          fullWidth
        />
        <TextField
          value={login}
          onChange={({ target }) => setLogin(target.value)}
          label="Usuário"
          variant="outlined"
          fullWidth
        />
        <TextField
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          label="Senha"
          variant="outlined"
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel>Nível de Acesso</InputLabel>
          <Select
            value={level}
            label="Nível de Acesso"
            onChange={({ target }) => setLevel(target.value)}
            readOnly={teacher}
            defaultValue={teacher && "teacher"}
          >
            {!teacher &&
              itens.map((item) => (
                <MenuItem key={item.level} value={item.level}>
                  {item.label}
                </MenuItem>
              ))}
            <MenuItem value={"teacher"}>PROFESSOR</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={() => handleRegister()}
        >
          Cadastrar
        </Button>
      </InputsArea>
      <CustomAlert
        infoType={infoType}
        status={status}
        setStatus={setStatus}
        infoMessage={infoMessage}
      />
    </Container>
  );
}
