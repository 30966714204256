import React from "react";
import Header from "../components/Header";
import IconsBar from "../components/IconsBar/IconsBar";
import News from "../components/News/News";

const Home = () => {
  return (
    <div>
      <Header />
      <IconsBar />
      <News />
    </div>
  );
};

export default Home;
