import React from "react";
import {
  Container,
  QuestionText,
  QuestionsArea,
  TitleArea,
  TitleText,
} from "./styles";
import { RadioButtonSys } from "../RadioButtonSys/RadioButtonSys";
import { Rating } from "@mui/material";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import CloseIcon from "@mui/icons-material/Close";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import CatchingPokemonIcon from "@mui/icons-material/CatchingPokemon";

export const Cleaning = ({
  title,
  question1,
  question2,
  question3,
  question4,
  positiveTitle,
  negativeTitle,
  option1,
  setOption1,
  option2,
  setOption2,
  option3,
  setOption3,
  option4,
  setOption4,
  pee,
  setPee,
  evacuate,
  setEvacuate,
  fraud,
  setFraud,
}) => {
  return (
    <Container>
      <TitleArea>
        <TitleText>{title}</TitleText>
      </TitleArea>
      <QuestionsArea>
        <QuestionText>Fez xixi?</QuestionText>
        <Rating
          icon={<InvertColorsIcon />}
          emptyIcon={<CloseIcon />}
          max={6}
          value={pee}
          onChange={(event, newValue) => {
            setPee(newValue);
          }}
        />
        <QuestionText>Fez cocô?</QuestionText>
        <Rating
          icon={<CatchingPokemonIcon />}
          emptyIcon={<CloseIcon />}
          max={6}
          value={evacuate}
          onChange={(event, newValue) => {
            setEvacuate(newValue);
          }}
        />
        <QuestionText>Troca de fraudas: </QuestionText>
        <Rating
          color="#d9d9d9"
          icon={<BabyChangingStationIcon />}
          emptyIcon={<CloseIcon />}
          max={6}
          value={fraud}
          onChange={(event, newValue) => {
            setFraud(newValue);
          }}
        />
      </QuestionsArea>

      <QuestionsArea>
        <QuestionText>{question1}</QuestionText>
        <RadioButtonSys
          positiveTitle={positiveTitle}
          negativeTitle={negativeTitle}
          option={option1}
          setOption={setOption1}
        />
        <QuestionText>{question2}</QuestionText>
        <RadioButtonSys
          positiveTitle={positiveTitle}
          negativeTitle={negativeTitle}
          option={option2}
          setOption={setOption2}
        />
      </QuestionsArea>
      <QuestionsArea>
        <QuestionText>{question3}</QuestionText>
        <RadioButtonSys
          positiveTitle={"Suficiente"}
          negativeTitle={"Insuficiente"}
          option={option3}
          setOption={setOption3}
        />
        <QuestionText>{question4}</QuestionText>
        <RadioButtonSys
          positiveTitle={positiveTitle}
          negativeTitle={negativeTitle}
          option={option4}
          setOption={setOption4}
        />
      </QuestionsArea>
    </Container>
  );
};
