import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Container, RadioLabel } from "./styles";

export const RadioButtonSys = ({
  positiveTitle,
  negativeTitle,
  option,
  setOption,
}) => {
  return (
    <Container>
      <FormControl>
        <RadioGroup
          row
          value={option}
          onChange={({ target }) => setOption(target.value)}
        >
          <FormControlLabel
            value={"positive"}
            control={<Radio />}
            label={<RadioLabel>{positiveTitle}</RadioLabel>}
          />
          <FormControlLabel
            value={"negative"}
            control={<Radio />}
            label={<RadioLabel>{negativeTitle}</RadioLabel>}
          />
        </RadioGroup>
      </FormControl>
    </Container>
  );
};
