import React from "react";
import Header from "../components/Header";
import IconsBar from "../components/IconsBar/IconsBar";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
`;

const Study = () => {
  return (
    <Container>
      <Header />
      <IconsBar />
      <h1>Roteiro de Estudos</h1>
    </Container>
  );
};

export default Study;
