import React, { useEffect } from "react";
import {
  Container,
  FoodsArea,
  SelectArea,
  SelectItem,
  TextSelection,
  TitleArea,
  TitleText,
} from "./styles";
import { ReactComponent as IconHappy } from "../../assets/icon_happy.svg";
import { ReactComponent as IconSad } from "../../assets/icon_sad.svg";
import { ReactComponent as IconUnavailable } from "../../assets/unavailable_icon.svg";
import { ActionButton } from "../../styles/GlobalStyles";
import { COLORS } from "../../utils/COLORS";
import { CheckBoxSys } from "../CkeckBoxSys/CheckBoxSys";

export const Foods = ({
  title,
  foods,
  selection,
  setSelection,
  qtdFood,
  setQtdFood,
  unavailable,
  setUnavailable,
}) => {
  useEffect(() => {
    if (selection.length === 0) {
      setUnavailable(true);
    } else {
      setUnavailable(false);
    }
  }, [selection]);
  return (
    <Container>
      <TitleArea>
        <TitleText>{title}</TitleText>
        <SelectArea>
          {unavailable ? (
            <>
              <SelectItem
                selected={selection.length === 0 && true}
                unavailable={unavailable}
              >
                <IconUnavailable width={18} height={18} />
                <TextSelection color={COLORS.danger}>
                  Não Aplicável
                </TextSelection>
              </SelectItem>
            </>
          ) : (
            <>
              <ActionButton onClick={() => setQtdFood(true)}>
                <SelectItem selected={qtdFood} unavailable={unavailable}>
                  <IconHappy width={20} height={20} />
                  <TextSelection color={COLORS.success}>
                    Comeu Tudo
                  </TextSelection>
                </SelectItem>
              </ActionButton>
              <ActionButton onClick={() => setQtdFood(false)}>
                <SelectItem selected={!qtdFood} unavailable={unavailable}>
                  <IconSad width={20} height={20} />
                  <TextSelection color={COLORS.danger}>
                    Comeu Pouco
                  </TextSelection>
                </SelectItem>
              </ActionButton>
            </>
          )}
        </SelectArea>
      </TitleArea>
      <FoodsArea>
        {foods.length > 0 &&
          foods.map((food) => (
            <CheckBoxSys
              id={food.id}
              key={food.id}
              title={food.name}
              selectedFoods={selection}
              setSelectedFoods={setSelection}
            />
          ))}
      </FoodsArea>
    </Container>
  );
};
