import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import IconsBar from "../components/IconsBar/IconsBar";
import styled from "styled-components";
import { Button } from "@mui/material";
import { sendGraphDiary } from "../api/autenticoAPI";
import { ClassroomSelect } from "../components/ClassroomSelect";
import { useNavigate } from "react-router-dom";
import { getAllClassrooms } from "../api/sponteAPI";

const GraphDiary = () => {
  const [selectedImage, setSelectedImage] = useState("");
  const [classrooms, setClassrooms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllClassrooms().then((res) => setClassrooms(res));
  }, []);

  const sendFileGraphDiary = () => {
    const fData = new FormData();
    fData.append("file", selectedImage);
    fData.append("body", classrooms[0]);
    fData.append("type", "FILE");
    fData.append("classroom_id", classrooms[1]);
    fData.append("class_name", classrooms[0]);
    sendGraphDiary(fData, "FILE");
    navigate("/autentico/stats");
    alert("Agenda enviada com sucesso!");
  };
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  return (
    <Container>
      <Header />
      <IconsBar />
      <ContentArea>
        <h3 className="page-title">Agenda Diária Modo Gráfico</h3>
        <ClassroomSelect
          classrooms={classrooms}
          setClassrooms={setClassrooms}
        />
        <FormItens encType="multipart/form-data">
          {selectedImage && (
            <img
              alt="Preview da Agenda"
              className="img-preview"
              src={URL.createObjectURL(selectedImage)}
            />
          )}
          <div className="file-send">
            <input type="file" onChange={(e) => imageChange(e)} />
            <Button
              onClick={() => {
                sendFileGraphDiary();
              }}
              variant="contained"
            >
              Enviar Agenda
            </Button>
          </div>
        </FormItens>
      </ContentArea>
    </Container>
  );
};

export default GraphDiary;

//STYLES

const Container = styled.div`
  text-align: center;
`;

const ContentArea = styled.div`
  margin: 15px auto;
  background-color: #f0eeee;
  width: 100%;
  max-width: 800px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .page-title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: bold;
    color: #004d93;
  }

  .img-preview {
    height: 200px;
    margin-bottom: 20px;
    width: 100%;
    object-fit: contain;
    border-radius: 20px;
  }
`;

const FormItens = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  min-height: 300px;
  .file-send {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input[type="file"] {
      margin-bottom: 10px;
    }
  }
`;
