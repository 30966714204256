import React from "react";
import { Snackbar } from "@mui/base";
import { Alert } from "@mui/material";

export const ToastAlert = ({ show, setShow, type, message }) => {
  return (
    <Snackbar
      open={show}
      autoHideDuration={5000}
      onClose={() => setShow(false)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      style={{
        position: "fixed",
        top: "10%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Alert
        variant="filled"
        onClose={() => setShow(false)}
        severity={type}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
