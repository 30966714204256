import React, { useEffect, useState } from "react";
import { Container, DocumentLink, InputsArea, LinkArea } from "./styles";
import { Alert, Autocomplete, Snackbar, TextField } from "@mui/material";
import { addRegister, getAccessData } from "../../api/autenticoAPI";
import { Loading } from "../Loading/Loading";

import {
  ActionsArea,
  DividerAreaRight,
  FormItens,
  PageTitle,
} from "../../pages/Announcements/styles";
import { getAllStudents, getStudentByID } from "../../api/sponteAPI";

export const SearchDocuments = () => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [uniqueStudentNames, setUniqueStudentNames] = useState([]);
  const [showFolder, setShowFolder] = useState(null);

  useEffect(() => {
    setLoading(true);
    getAllStudents()
      .then((res) => {
        const names = res.map((item) => `${item.AlunoID}-${item.Aluno}`);
        const uniqueNames = [...new Set(names)]; // Remove nomes duplicados
        setUniqueStudentNames(uniqueNames);
      })
      .then(() => setLoading(false));
  }, []);
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleStudentSelect = (event, newValue) => {
    setShowFolder(null);
    setSelectedStudent(newValue);
    searchDocumentsByStudent(newValue);
  };

  const filteredStudents = uniqueStudentNames.filter((name) =>
    name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const searchDocumentsByStudent = async (newValue) => {
    if (newValue) {
      setLoading(true);
      const AlunoID = newValue.split("-")[0];
      const folderLink = await getStudentByID(AlunoID);
      if (folderLink) {
        setShowFolder(folderLink);
      }
      setLoading(false);
    }
  };

  return (
    <Container>
      <PageTitle>Consulta</PageTitle>

      <Autocomplete
        onChange={handleStudentSelect}
        disablePortal
        options={filteredStudents}
        sx={{ width: "80%", margin: "10px auto" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Digite parte do nome do aluno para consulta"
            onChange={handleSearchChange}
          />
        )}
      />
      <FormItens encType="multipart/form-data">
        <ActionsArea>
          {showFolder && (
            <LinkArea>
              <p>Clique na imagem para acessar</p>
              <DocumentLink
                href={showFolder}
                target="_blank"
                rel="noopener noreferrer"
                className="link-drive"
              >
                <img
                  src="https://www.gstatic.com/images/branding/product/2x/drive_2020q4_48dp.png"
                  alt="Google Drive Folder Icon"
                />
              </DocumentLink>
            </LinkArea>
          )}
        </ActionsArea>
      </FormItens>
      {loading && <Loading />}
    </Container>
  );
};
