import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import Skeleton from "@mui/joy/Skeleton";
import Stack from "@mui/joy/Stack";

export default function ImageSkeleton({ file }) {
  return (
    <Stack spacing={2} useFlexGap sx={{ alignItems: "center" }}>
      <Box sx={{ m: "auto" }}>
        <AspectRatio variant="plain" minHeight={300} sx={{ width: 250 }}>
          <Skeleton loading={file === ""}>
            <img
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              src={
                file === ""
                  ? "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                  : URL.createObjectURL(file)
              }
              alt=""
            />
          </Skeleton>
        </AspectRatio>
      </Box>
      <FormControl orientation="horizontal" sx={{ gap: 1 }}></FormControl>
    </Stack>
  );
}
