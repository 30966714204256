import React from "react";
import { Container, ModalBody, ModalTitle, TitleText } from "./styles";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { ActionButton } from "../../styles/GlobalStyles";

export const CustomModal = ({ status, setStatus, children, title }) => {
  return (
    <Container status={status}>
      <ModalBody>
        <ModalTitle>
          <TitleText>{title}</TitleText>
          <ActionButton onClick={() => setStatus(false)}>
            <CloseIcon width={30} heigth={30} />
          </ActionButton>
        </ModalTitle>
        {children}
      </ModalBody>
    </Container>
  );
};
