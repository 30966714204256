import { TextField } from "@mui/material";
import { getAllAnnouncements } from "../../api/autenticoAPI";
import NotifyItem from "../NotifyItem";
import React, { useEffect, useState } from "react";
import { FixedSizeList } from "react-window";
import { CustomAlert } from "../CustomAlert/CustomAlert";

export const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [filteredAnnouncements, setFilteredAnnouncements] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const updateAnnouncementsList = () => {
    getAllAnnouncements().then((res) => {
      setAnnouncements(res.annoucements);
      setFilteredAnnouncements(res.annoucements);
    });
  };

  useEffect(() => {
    updateAnnouncementsList();
  }, []);

  const handleTitleSearch = (event) => {
    if (event.key === "Enter") {
      let results = announcements.filter((item) =>
        item.title.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setFilteredAnnouncements(results);
    }
  };

  const Row = ({ index, style }) => (
    <div style={style}>
      <NotifyItem
        data={filteredAnnouncements[index]}
        updateAnnouncementsList={updateAnnouncementsList}
        setInfoMessage={setInfoMessage}
        setShowMessage={setShowMessage}
      />
    </div>
  );

  return (
    <>
      <TextField
        style={{ marginBottom: 20 }}
        autoComplete="off"
        className="search"
        id="title-search"
        label='Digite o título do comunicado e pressione "Enter"'
        variant="outlined"
        onKeyDown={handleTitleSearch}
      />
      <FixedSizeList
        style={{ margin: "auto" }}
        height={400} // Altura da lista
        width="90%" // Largura da lista
        itemSize={52} // Altura de cada item
        itemCount={filteredAnnouncements.length} // Número total de itens
      >
        {Row}
      </FixedSizeList>
      <CustomAlert
        infoType="success"
        status={showMessage}
        setStatus={setShowMessage}
        infoMessage={infoMessage}
      />
    </>
  );
};
