import React from "react";
import Header from "../components/Header";
import IconsBar from "../components/IconsBar/IconsBar";
import styled from "styled-components";
import { StatsTabs } from "../components/StatsTabs";

const Container = styled.div`
  text-align: center;
`;
const ContentArea = styled.div`
  width: 80%;
  margin: 20px auto;
  .search {
    width: 80%;
  }
`;

const Stats = () => {
  return (
    <Container>
      <Header />
      <IconsBar />
      <ContentArea>
        <StatsTabs />
      </ContentArea>
    </Container>
  );
};

export default Stats;
