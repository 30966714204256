import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Diaries } from "./StatsParts/Diaries";
import { Announcements } from "./StatsParts/Announcements";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function StatsTabs() {
  const [value, setValue] = useState(0);
  const [date, setDate] = useState(new Date());
  const [diariesFocused, setDiariesFocused] = useState(false);
  const isMobile = window.innerWidth <= 600;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDiariesTabFocus = () => {
    if (value !== 1) {
      setValue(1);
    } else {
      setDiariesFocused(true);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Estatísticas"
          variant={isMobile ? "scrollable" : "standard"}
          scrollButtons={isMobile ? "auto" : "off"}
          allowScrollButtonsMobile={isMobile}
          centered
        >
          <Tab label="Comunicados" {...a11yProps(0)} />
          <Tab
            label="Agendas"
            {...a11yProps(1)}
            onFocus={handleDiariesTabFocus}
          />
          <Tab label="Financeiro" {...a11yProps(2)} disabled />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Announcements date={date} setDate={setDate} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Diaries date={date} setDate={setDate} focused={diariesFocused} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        Financeiro
      </TabPanel>
    </Box>
  );
}
