import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { getAllClassrooms } from "../api/sponteAPI";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export const MultipleClassroomSelect = ({ classrooms, setClassrooms }) => {
  const [classes, setClasses] = React.useState([]);
  const [selectedClass, setSelectedClass] = React.useState([]);

  React.useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const response = await getAllClassrooms();
        const classrooms = response.map((classroom) => ({
          Nome: classroom.Nome,
          TurmaID: classroom.TurmaID,
        }));
        setClasses(classrooms);
      } catch (error) {
        console.error(error);
      }
    };
    fetchClassrooms();
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedClass(value);
    setClassrooms(value);
  };

  return (
    <div>
      <FormControl sx={{ width: "80%" }}>
        <InputLabel id="demo-multiple-checkbox-label">
          Selecione uma turma ou um conjunto de turmas
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={selectedClass}
          onChange={handleChange}
          input={
            <OutlinedInput label="Selecione uma turma ou um conjunto de turmas" />
          }
          renderValue={(selected) =>
            selected
              .map((value) => {
                const classroom = classes.find((c) => c.TurmaID === value);
                return classroom ? classroom.Nome : value;
              })
              .join(", ")
          }
          MenuProps={MenuProps}
          multiple
        >
          {classes &&
            classes.map((classroom) => (
              <MenuItem key={classroom.TurmaID} value={classroom.TurmaID}>
                <Checkbox
                  checked={classrooms.indexOf(classroom.TurmaID) > -1}
                />
                <ListItemText primary={classroom.Nome} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};
