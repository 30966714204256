import React from "react";
import {
  Container,
  QuestionText,
  QuestionsArea,
  TitleArea,
  TitleText,
} from "./styles";
import { RadioButtonSys } from "../RadioButtonSys/RadioButtonSys";
import { TimeSlider } from "../IntegranteItem/SliderSleepTime";

export const Form = ({
  title,
  question1,
  question2,
  positiveTitle,
  negativeTitle,
  option1,
  setOption1,
  option2,
  setOption2,
  sleepTime,
  entryHours,
  setEntryHours,
  entryMinutes,
  setEntryMinutes,
  exitHours,
  setExitHours,
  exitMinutes,
  setExitMinutes,
}) => {
  return (
    <Container>
      <TitleArea>
        <TitleText>{title}</TitleText>
      </TitleArea>
      <QuestionsArea>
        <QuestionText>{question1}</QuestionText>
        <RadioButtonSys
          positiveTitle={positiveTitle}
          negativeTitle={negativeTitle}
          option={option1}
          setOption={setOption1}
        />
        <QuestionText>{question2}</QuestionText>
        <RadioButtonSys
          positiveTitle={positiveTitle}
          negativeTitle={negativeTitle}
          option={option2}
          setOption={setOption2}
        />
        {sleepTime && option2 === "positive" && (
          <TimeSlider
            entryHours={entryHours}
            setEntryHours={setEntryHours}
            entryMinutes={entryMinutes}
            setEntryMinutes={setEntryMinutes}
            exitHours={exitHours}
            setExitHours={setExitHours}
            exitMinutes={exitMinutes}
            setExitMinutes={setExitMinutes}
          />
        )}
      </QuestionsArea>
    </Container>
  );
};
