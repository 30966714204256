import React from "react";
import { useContext } from "react";
import Login from "../../pages/Login";
import { AuthContext } from "./AuthContext";

export const RequireAuth = ({ children, permition }) => {
  const { user } = useContext(AuthContext);
  if (permition === "all") {
    return children;
  }
  if (!user) {
    return <Login />;
  }
  if (!permition.includes(user.level)) {
    return <Login />;
  }
  return children;
};
