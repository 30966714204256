import React, { useState } from "react";
import { Container, Title } from "./styles";
import { ReactComponent as CKTrue } from "../../assets/ck_true.svg";
import { ReactComponent as CKFalse } from "../../assets/ck_false.svg";
import { ActionButton } from "../../styles/GlobalStyles";

export const CheckBoxSys = ({ id, title, selectedFoods, setSelectedFoods }) => {
  const [checked, setChecked] = useState(
    selectedFoods.some((item) => item.name === title)
  );
  const handleCheck = (id) => {
    if (!checked) {
      setSelectedFoods([...selectedFoods, { id, name: title }]);
    } else {
      setSelectedFoods(selectedFoods.filter((item) => item.id !== id));
    }
    setChecked(!checked);
  };
  return (
    <Container>
      <ActionButton onClick={() => handleCheck(id)}>
        {!checked ? (
          <CKFalse width={15} height={15} />
        ) : (
          <CKTrue width={15} height={15} />
        )}
        <Title>{title}</Title>
      </ActionButton>
    </Container>
  );
};
