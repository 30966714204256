import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  display: ${({ status }) => (status ? "flex" : "none")};
  inset: 0;
  justify-content: center;
  position: fixed;
  z-index: 900;
  overflow-y: auto;
`;
export const ModalTitle = styled.div`
  align-items: center;
  background-color: rgba(0, 77, 147, 0.7);
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 10px;
`;
export const TitleText = styled.span`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
`;
export const ModalBody = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  top: 0;
`;
