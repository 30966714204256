export const sendOneSignalNotifications = ({
  name,
  userType,
  idSponte,
  unit,
  ids,
  message,
}) => {
  const sendNotificationWithFilter = (filter) => {
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: "Basic OTg5NzU1NGUtZGNhYS00NWQ1LThmNGItYWY0MmUxZmIwOTFi",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        app_id: "31860986-7f49-44ac-8552-84fd749ee8fb",
        headings: { en: name },
        filters: [
          filter,
          {
            field: "tag",
            key: "unit",
            relation: "=",
            value: unit,
          },
        ],
        contents: { en: message },
        name,
      }),
    };

    fetch("https://onesignal.com/api/v1/notifications", options)
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  };

  if (userType) {
    const userTypeFilter = {
      field: "tag",
      key: "userType",
      relation: "=",
      value: userType,
    };
    sendNotificationWithFilter(userTypeFilter);
  }

  if (idSponte) {
    const idSponteFilter = {
      field: "tag",
      key: "idSponte",
      relation: "=",
      value: idSponte,
    };
    sendNotificationWithFilter(idSponteFilter);
  }

  if (ids) {
    ids.forEach((id) => {
      const idSponteFilter = {
        field: "tag",
        key: "idSponte",
        relation: "=",
        value: id,
      };
      sendNotificationWithFilter(idSponteFilter);
    });
  }
};
