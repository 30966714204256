import React, { useEffect } from "react";
import { useState } from "react";
import { useAPI } from "../../hooks/useAPI";
import { AuthContext } from "./AuthContext";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const API = useAPI();

  useEffect(() => {
    const validateUser = async () => {
      const storageData = sessionStorage.getItem("loggedUser");
      if (storageData) {
        setUser(JSON.parse(storageData));
      }
    };
    validateUser();
  }, []);

  const signin = async (login, password, _type, unit) => {
    const data = await API.signin(login, password, unit);
    if (data.user) {
      setUser(data.user);
      setLoggedUser(data.user);
      return true;
    }
    return false;
  };
  const signout = async () => {
    setUser(null);
    setLoggedUser("");
  };
  const setLoggedUser = (user) => {
    sessionStorage.setItem("loggedUser", JSON.stringify(user));
  };
  return (
    <AuthContext.Provider value={{ user, signin, signout }}>
      {children}
    </AuthContext.Provider>
  );
};
