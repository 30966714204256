import React, { useState, useRef } from "react";
import { setCaptions } from "../api/autenticoAPI";

function EditableText({
  controller,
  defaultTitle,
  dbCaptions,
  setDbCaptions,
  readOnly,
}) {
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(defaultTitle);
  const inputRef = useRef(null);

  const handleClick = () => {
    if (!editing) {
      setEditing(true);
    }
  };

  const handleDoubleClick = () => {
    setEditing(true);
  };

  const handleBlur = () => {
    if (inputRef.current.value.trim() === "") {
      setName(name);
    } else {
      dbCaptions[controller] = inputRef.current.value;
      setCaptions(dbCaptions);
      setName(inputRef.current.value);
    }
    setEditing(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleBlur();
    }
  };

  return (
    <div>
      {editing ? (
        <input
          type="text"
          defaultValue={name}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          ref={inputRef}
          autoFocus
          style={{ width: "60px" }}
          readOnly={readOnly}
        />
      ) : (
        <div
          onDoubleClick={handleDoubleClick}
          onClick={handleClick}
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "12px",
            width: "60px",
            textAlign: "center",
            color: "#004D93",
          }}
        >
          {name}
        </div>
      )}
    </div>
  );
}

export default EditableText;
