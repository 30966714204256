import axios from "axios";
import { CAMPINA_API, PATOS_API } from "../api/InstanceAPI";
const createInstanceAPI = (unit) => {
  const BASE_URL = unit === "CAMPINA GRANDE" ? CAMPINA_API : PATOS_API;
  return axios.create({
    baseURL: BASE_URL,
  });
};
export const useAPI = () => ({
  validateToken: async (token) => {
    const data = {
      user: {
        id: 5,
        name: "Albert Einstein",
        email: "einstein@colegio.com",
      },
    };
    return data;
  },
  signin: async (login, password, unit) => {
    const api = createInstanceAPI(unit);
    const response = await api.post(`/access/login`, { login, password });
    return response.data;
  },
  logout: async (unit) => {
    const api = createInstanceAPI(unit);
    const response = await api.post("/users/logout");
    return response.data;
  },
});
