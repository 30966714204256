import axios from "axios";
export const CAMPINA_API = "https://colegioautentico.app.br/campina/api";
export const PATOS_API = "https://colegioautentico.app.br/patos/api";

export const createApiInstance = () => {
  const storageData = sessionStorage.getItem("loggedUser");
  let user = "";

  if (storageData) {
    user = JSON.parse(storageData);
  }

  const BASE_URL = user.unit === "CAMPINA GRANDE" ? CAMPINA_API : PATOS_API;

  return axios.create({
    baseURL: BASE_URL,
  });
};
