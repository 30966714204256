import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export const PeriodSelect = ({ period, setPeriod }) => {
  const ListOfPeriods = [
    { name: "1º Bimestre", value: 1 },
    { name: "2º Bimestre", value: 2 },
    { name: "3º Bimestre", value: 3 },
    { name: "4º Bimestre", value: 4 },
  ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPeriod(value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 350 }}>
        <InputLabel id="period-select">Período</InputLabel>
        <Select
          labelId="period-select"
          value={period || ""}
          onChange={handleChange}
          input={<OutlinedInput label="Período" />}
          renderValue={(selected) => {
            const selectedPeriod = ListOfPeriods.find(
              (p) => p.value === selected
            );
            return selectedPeriod ? selectedPeriod.name : "";
          }}
          MenuProps={MenuProps}
        >
          {ListOfPeriods.map((periodOption) => (
            <MenuItem key={periodOption.value} value={periodOption.value}>
              <Checkbox checked={periodOption.value === period} />
              <ListItemText primary={periodOption.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
