import styled from "styled-components";

export const ActionButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  :hover {
    opacity: 0.5;
  }
`;
