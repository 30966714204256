import React from "react";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const TextLabel = styled.span`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;

export function TimeSlider({
  entryHours,
  setEntryHours,
  entryMinutes,
  setEntryMinutes,
  exitHours,
  setExitHours,
  exitMinutes,
  setExitMinutes,
}) {
  const handleEntryHoursChange = (event, newValue) => {
    setEntryHours(newValue);
  };

  const handleEntryMinutesChange = (event, newValue) => {
    setEntryMinutes(newValue);
  };

  const handleExitHoursChange = (event, newValue) => {
    setExitHours(newValue);
  };

  const handleExitMinutesChange = (event, newValue) => {
    setExitMinutes(newValue);
  };

  const formatTime = (hours, minutes) => {
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <Container>
      <TextLabel>Defina o horário</TextLabel>
      <Slider
        value={entryHours}
        onChange={handleEntryHoursChange}
        aria-labelledby="entryHours-slider"
        min={7}
        max={18}
        step={1}
        style={{ width: "200px" }}
      />
      <Slider
        value={entryMinutes}
        onChange={handleEntryMinutesChange}
        aria-labelledby="entryMinutes-slider"
        min={0}
        max={30}
        step={30}
      />

      <Typography>{formatTime(entryHours, entryMinutes)}</Typography>

      <Slider
        value={exitHours}
        onChange={handleExitHoursChange}
        aria-labelledby="exitHours-slider"
        min={7}
        max={18}
        step={1}
      />
      <Slider
        value={exitMinutes}
        onChange={handleExitMinutesChange}
        aria-labelledby="exitMinutes-slider"
        min={0}
        max={30}
        step={30}
      />

      <Typography>{formatTime(exitHours, exitMinutes)}</Typography>
    </Container>
  );
}
