import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 20px 40px;
  background-color: rgba(217, 217, 217, 0.4);
  border-radius: 20px;
  gap: 10px 10px;
  img {
    width: 400px;
    margin: auto;
    max-width: 100%;
  }
  .select-type {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (min-width: 768px) {
    width: 50%;
  }
`;

export const PageCaption = styled.span`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #1e958c;
`;

export const VersionCode = styled.span`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #ab0505;
`;
