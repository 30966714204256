import styled from "styled-components";

export const Container = styled.div``;
export const ContainerBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const ComponentsArea = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
  width: 50%;
`;

export const InputsArea = styled.div`
  max-width: 50%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
export const DataTitle = styled.span`
  color: #136905;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 10px auto;
`;
export const DataArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const DataGroup = styled.div``;
export const DataSubtitle = styled.span`
  color: #004d93;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;
export const DataLabel = styled.span`
  color: #868383;
  font-size: 15px;
  margin-right: 5px;
  text-align: center;
`;
export const DataValue = styled.span`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;
