import React from "react";
import {
  AllocationArea,
  AllocationData,
  ClassroomName,
  SectionTitle,
  SubjectName,
  Subjects,
  SubjectsArea,
  TeacherName,
} from "./styles";

export const AllocationItem = ({ allocation }) => {
  return (
    <AllocationArea>
      <AllocationData>
        <SectionTitle>Dados da Alocação</SectionTitle>
        <TeacherName>{allocation.teacherName}</TeacherName>
        <ClassroomName>{allocation.turmaName}</ClassroomName>
      </AllocationData>
      <Subjects>
        <SectionTitle>Disciplinas</SectionTitle>
        <SubjectsArea>
          {allocation.hasOwnProperty("Disciplinas") &&
            allocation.Disciplinas.map((item, index) => (
              <SubjectName key={index}>{item.disciplinaName}</SubjectName>
            ))}
        </SubjectsArea>
      </Subjects>
    </AllocationArea>
  );
};
