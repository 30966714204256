import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export const TeacherSelect = ({ teacher, setTeacher, teachers }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setTeacher(value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 350 }}>
        <InputLabel id="subject-select">Professores</InputLabel>
        <Select
          labelId="subject-select"
          value={teacher.name || ""}
          onChange={handleChange}
          input={<OutlinedInput label="Professores" />}
          renderValue={(selected) => {
            const selectSubject = teachers.find((p) => p.name === selected);
            return selectSubject ? selectSubject.name : "";
          }}
          MenuProps={MenuProps}
        >
          {teachers &&
            teachers.map((subjectOption) => (
              <MenuItem
                key={subjectOption.id}
                value={{ id: subjectOption.id, name: subjectOption.name }}
              >
                <Checkbox checked={subjectOption.id === teacher.id} />
                <ListItemText primary={subjectOption.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};
