import styled from "styled-components";

export const Container = styled.div``;
export const ContentArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
`;
export const TitlePage = styled.span`
  color: #1e958c;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
`;
export const StudentsList = styled.div`
  width: 70%;
  height: 450px;
  overflow-y: scroll;
  @media only screen and (max-width: 820px) {
    width: 85%;
    height: 800px;
  }
`;
export const SelectionArea = styled.div`
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;
