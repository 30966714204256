import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  allocateTeacher,
  deAllocateTeacher,
  getTeachers,
  getTeachersAllocations,
} from "../../api/autenticoAPI";
import { getSubjects } from "../../api/sponteAPI";
import { AlertDialog } from "../AlertDialog";
import { ClassroomSelect } from "../ClassroomSelect";
import { SubjectSelect } from "../SubjectSelect";
import { TeacherSelect } from "../TeacherSelect";
import { ToastAlert } from "../ToastAlert";
import { AllocationItem } from "./AllocationItem";
import { ButtonsArea, Container, SelectionArea } from "./styles";

export const TeachersAllocation = () => {
  const [classrooms, setClassrooms] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState();
  const [teachers, setTeachers] = useState([]);
  const [teacher, setTeacher] = useState({ id: null, name: null });
  const [allocations, setAllocations] = useState([]);
  const [open, setOpen] = useState(false);
  const [bodyDialog, setBodyDialog] = useState("");
  const [type, setType] = useState("");
  const [style, setStyle] = useState("");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  const loadAllocations = () => {
    getTeachersAllocations(teacher.id, null, Number(classrooms[1])).then(
      (res) => setAllocations(res)
    );
  };

  useEffect(() => {
    if (classrooms.length > 0) {
      getSubjects(classrooms[1]).then((res) =>
        setSubjects(
          Array.isArray(res.Disciplinas) ? res.Disciplinas : [res.Disciplinas]
        )
      );
    }
    getTeachers().then((res) => setTeachers(res));
    setSubject(undefined);
    loadAllocations();
  }, [classrooms]);

  useEffect(() => {
    if (teacher.id) {
      getTeachersAllocations(teacher.id).then((res) => setAllocations(res));
    }
  }, [teacher]);

  const handlleAllocate = async () => {
    try {
      const { status, message } = await allocateTeacher(
        teacher.id,
        teacher.name,
        classrooms[1],
        classrooms[0],
        subject.id,
        subject.name
      );
      if (status) {
        setMessage(message);
        setStyle("success");
        setShow(true);
      } else {
        throw new Error();
      }
    } catch (error) {
      setMessage("Alocação com os parâmetros informados já existe!");
      setStyle("error");
      setShow(true);
      console.log(error);
    }
    loadAllocations();
  };
  const deallocateType = async () => {
    if (type === "subject") {
      await deAllocateTeacher(teacher.id, classrooms[1], subject.id);
    }
    if (type === "classroom") {
      await deAllocateTeacher(teacher.id, classrooms[1]);
    }
    if (type === "teacher") {
      await deAllocateTeacher(teacher.id);
    }
    loadAllocations();
  };
  const handlleDeAllocate = async () => {
    if (teacher.id && classrooms[1] && subject) {
      setBodyDialog(
        "Esse procedimento irá remover apenas a disciplina selecionada deste professor na turma! Você deseja confirmar essa ação?"
      );
      setType("subject");
      setOpen(true);
    } else {
      if (teacher.id && classrooms[1]) {
        setBodyDialog(
          "Esse procedimento irá remover o professor selecionado de todas as disciplinas dessa turma! Você deseja confirmar essa ação?"
        );
        setType("classroom");
        setOpen(true);
      } else {
        if (teacher.id) {
          setBodyDialog(
            "Esse procedimento irá remover o professor selecionado de todas as turmas e disciplinas que o mesmo leciona! Você deseja confirmar essa ação?"
          );
          setType("teacher");
          setOpen(true);
        }
      }
    }

    loadAllocations();
  };
  return (
    <Container>
      <SelectionArea>
        <TeacherSelect
          teacher={teacher}
          setTeacher={setTeacher}
          teachers={teachers}
        />
        <ClassroomSelect
          classrooms={classrooms}
          setClassrooms={setClassrooms}
        />
        <SubjectSelect
          subject={subject}
          setSubject={setSubject}
          subjects={subjects}
        />
      </SelectionArea>
      <ButtonsArea>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={() => handlleAllocate()}
        >
          Alocar
        </Button>
        <Button
          variant="contained"
          fullWidth
          color="error"
          onClick={() => handlleDeAllocate()}
        >
          Desalocar
        </Button>
        <AlertDialog
          open={open}
          setOpen={setOpen}
          title="Confirmação do tipo de desalocação!"
          body={bodyDialog}
          handleConfirm={() => deallocateType()}
        />
      </ButtonsArea>
      {allocations.map((item, index) => (
        <AllocationItem allocation={item} key={index} />
      ))}
      <ToastAlert
        show={show}
        setShow={setShow}
        type={style}
        message={message}
      />
    </Container>
  );
};
