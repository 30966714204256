import React from "react";
import styled from "styled-components";

const CardClassroom = ({ setSelected, setOpen, title, subjects }) => {
  const handleSubject = (classroom, subject) => {
    setSelected({ classroom, subject });
    setOpen(true);
  };
  return (
    <Container>
      <TitleArea>
        <Title>{title.name}</Title>
      </TitleArea>
      <SubjectsArea>
        {subjects.map((subject, index) => (
          <Icon
            key={index}
            onClick={() => {
              handleSubject(title, subject);
            }}
          >
            <img
              src={require(`../assets/subjects/${subject.icon}.svg`)}
              alt={subject.name}
            />
            <SubjectName>{subject.name}</SubjectName>
          </Icon>
        ))}
      </SubjectsArea>
    </Container>
  );
};

//STYLES

const Container = styled.div`
  width: 300px;
  height: 250px;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  box-shadow: 3px 3px 3px #d9d9d9;
`;
const TitleArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px 15px 0 0;
  background-color: #1e958c;
  height: 40px;
  width: 100%;
`;
const Title = styled.span`
  color: white;
  font-weight: bold;
  text-transform: uppercase;
`;
const SubjectsArea = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`;
const Icon = styled.button`
  border: none;
  background-color: white;
  cursor: pointer;
`;
const SubjectName = styled.p`
  font-weight: bold;
  text-transform: uppercase;
`;
export default CardClassroom;
