import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
`;

export const ContentArea = styled.div`
  margin: 15px auto;
  width: 100%;
  max-width: 800px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .page-title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: bold;
    color: #004d93;
  }

  .img-preview {
    height: 200px;
    margin-bottom: 20px;
    width: 100%;
    object-fit: contain;
    border-radius: 20px;
  }
`;

export const FormItens = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  min-height: 300px;
  .file-send {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input[type="file"] {
      margin-bottom: 10px;
    }
  }
`;
export const PageTitle = styled.span`
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  color: #1e958c;
  margin: 10px auto;
`;
export const ActionsArea = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

export const DividerAreaLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
`;
export const DividerAreaRight = styled.div`
  display: flex;
  gap: 50px;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 50%;
`;
export const UsersInfo = styled.span`
  text-align: center;
  color: #ab0505;
  font-weight: bold;
  font-size: 20px;
  max-width: 80%;
`;
export const AttachmentsArea = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;
