import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/Auth/AuthContext";
import Logout from "@mui/icons-material/Logout";
import styled from "styled-components";
import { ReactComponent as LogoA } from "../assets/a-logo.svg";
import packageJs from "../../package.json";

const InfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 20px;
  align-items: center;
  @media (max-width: 611px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const LogoArea = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
`;
const Logo = styled.span`
  font-size: 25px;
  @media (max-width: 600px) {
    font-size: 15px;
  }
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const UserName = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const VersionCode = styled.span`
  font-size: 12px;
  margin-left: 5px;
`;
const UnitInfo = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  color: white;
`;
const UserArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Header = () => {
  const auth = React.useContext(AuthContext);

  const navigate = useNavigate();

  const handleClose = async () => {
    await auth.signout(auth.user.unit);
    navigate("/");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <InfoHeader>
          <LogoArea href="/autentico/home">
            <LogoA width="60" height="50" />
            <Logo>Sistema de Gerenciamento Autêntico</Logo>
            <VersionCode>( Versão: {packageJs.version} )</VersionCode>
          </LogoArea>
          {auth.user && (
            <HeaderText>
              <UserArea>
                <UserName>{auth.user.name}</UserName>
                <UnitInfo>Unidade {auth.user.unit}</UnitInfo>
              </UserArea>
              <IconButton
                aria-label="delete"
                size="large"
                onClick={handleClose}
              >
                <Logout />
              </IconButton>
            </HeaderText>
          )}
        </InfoHeader>
      </AppBar>
    </Box>
  );
};

export default Header;
