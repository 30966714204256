import React from "react";
import { ComponentsArea, ContainerBox, DataTitle } from "./styles";
import { DataInfo } from "./DataInfo";

export const DataAccess = ({ users }) => {
  return (
    <ContainerBox>
      <DataTitle>Dados de Acesso</DataTitle>
      <ComponentsArea>
        {users.map((user, key) => (
          <DataInfo
            key={key}
            type={user.Nome}
            user={user.login}
            password={user.password}
          />
        ))}
      </ComponentsArea>
    </ContainerBox>
  );
};
