import styled from "styled-components";

export const Container = styled.div``;
export const InputsArea = styled.div`
  max-width: 50%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const SelectionArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonsArea = styled.div`
  display: flex;
  width: 80%;
  margin: 10px auto;
  gap: 30px;
  justify-content: space-around;
  align-items: center;
`;

export const AllocationArea = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const AllocationData = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Subjects = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AllocationSection = styled.div``;

export const SectionTitle = styled.span`
  font-size: 12px;
`;
export const TeacherName = styled.span`
  color: #004d93;
  font-size: 16px;
  font-weight: bold;
`;
export const ClassroomName = styled.span`
  color: #1e958c;
  font-size: 16px;
  font-weight: bold;
`;
export const SubjectsArea = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;
export const SubjectName = styled.span`
  color: #1e958c;
  font-size: 16px;
  font-weight: bold;
`;
