import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as FileView } from "../assets/file-view.svg";
import { ReactComponent as Attach } from "../assets/attach.svg";
import { ReactComponent as Links } from "../assets/links.svg";
import { ReactComponent as TrashFile } from "../assets/trash.svg";
import moment from "moment";
import { AlertDialog } from "./AlertDialog";
import { deleteAnnouncements } from "../api/autenticoAPI";

const Container = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;
const CaptionsArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;
const ButtonsArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const Title = styled.p`
  text-transform: uppercase;
  font-weight: bold;
  color: #004d93;
  font-size: 15px;
`;
const ClassName = styled.p`
  text-transform: uppercase;
  color: #136905;
  font-size: 12px;
`;

const NotifyItem = ({
  data,
  updateAnnouncementsList,
  setInfoMessage,
  setShowMessage,
}) => {
  const [open, setOpen] = useState(false);
  const handleDelete = async () => {
    try {
      await deleteAnnouncements(data.id);
      setInfoMessage("Comunicado excluído com sucesso!");
      setShowMessage(true);
      updateAnnouncementsList();
    } catch (error) {
      console.log(error);
      setInfoMessage("Erro ao excluir o comunicado!");
      setShowMessage(true);
    }
  };
  return (
    <Container>
      <CaptionsArea>
        <Title>{data.title}</Title>
        <ClassName>
          {data.classroom_name} -{" "}
          {moment(data.date).format("DD/MM/YYYY HH:mm:ss")}
        </ClassName>
      </CaptionsArea>
      <ButtonsArea>
        <a href={data.url} rel="noopener noreferrer" target="_blank">
          <FileView width={25} height={25} />
        </a>
        {Array.isArray(data.attachments) && data.attachments.length > 0 && (
          <Attach width={25} height={25} />
        )}
        {data.links && data.links[0] !== null && (
          <Links width={25} height={25} />
        )}
        <a href="#delete" onClick={() => setOpen(true)}>
          <TrashFile width={25} height={25} />
        </a>
      </ButtonsArea>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title="Confirmação de exclusão de comunicado!"
        body={"Você tem certeza que deseja excluir este comunicado?"}
        handleConfirm={() => handleDelete()}
      />
    </Container>
  );
};

export default NotifyItem;
