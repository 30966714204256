import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getAllStudents, getStudentByName } from "../../api/sponteAPI";
import { getAccessData } from "../../api/autenticoAPI";
import { Container } from "./styles";
import { DataAccess } from "./DataAccess";
import { Loading } from "../Loading/Loading";

export const SearchRegister = () => {
  const [loading, setLoading] = useState(false);
  const [uniqueStudentNames, setUniqueStudentNames] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [dataInfo, setDataInfo] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllStudents()
      .then((res) => {
        const names = res.map((item) => item.Aluno);
        const uniqueNames = [...new Set(names)]; // Remove nomes duplicados
        setUniqueStudentNames(uniqueNames);
      })
      .then(() => setLoading(false));
  }, []);
  useEffect(() => {
    if (selectedStudent) {
      setLoading(true);
    }
    getStudentByName(selectedStudent)
      .then((res) => getAccessData(res).then((res) => setDataInfo(res)))
      .then(() => setLoading(false));
  }, [selectedStudent]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredStudents = uniqueStudentNames.filter((name) =>
    name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const handleStudentSelect = (event, newValue) => {
    setSelectedStudent(newValue);
  };

  return (
    <Container>
      <Autocomplete
        onChange={handleStudentSelect}
        disablePortal
        options={filteredStudents}
        sx={{ width: "50%", margin: "10px auto" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Digite parte do nome do aluno para consulta"
            onChange={handleSearchChange}
          />
        )}
      />
      {dataInfo.length > 0 && <DataAccess users={dataInfo} />}
      {loading && <Loading />}
    </Container>
  );
};
