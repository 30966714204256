import React from "react";
import FormSignIn from "../components/FromSignIn/FormSignIn";

const Login = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FormSignIn />
    </div>
  );
};

export default Login;
