import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import IconsBar from "../../components/IconsBar/IconsBar";
import { Button, TextField } from "@mui/material";
import { sendOneSignalAnnouncements } from "../../api/autenticoAPI";
import {
  getAllClassrooms,
  getStudentsAndResponsablesByClassroom,
} from "../../api/sponteAPI";
import {
  ActionsArea,
  AttachmentsArea,
  Container,
  ContentArea,
  DividerAreaLeft,
  DividerAreaRight,
  FormItens,
  PageTitle,
  UsersInfo,
} from "./styles";
import { MultipleClassroomSelect } from "../../components/MultipleClassroomSelect";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinkIcon from "@mui/icons-material/Link";
import RefreshIcon from "@mui/icons-material/Refresh";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@material-ui/styles";
import ImageSkeleton from "./ImageSkeleton";
import { Loading } from "../../components/Loading/Loading";
import { sendOneSignalNotifications } from "../../services/oneSignal";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { AlertMessages } from "../../components/AlertMessages";
import { SecretaryTabs } from "../../components/SecretaryTabs/SecretaryTabs";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Secretary = () => {
  const [selectedImage, setSelectedImage] = useState("");
  const [anexosSelecionados, setAnexosSelecionados] = useState([]);
  const [linksInseridos, setLinksInseridos] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [title, setTitle] = useState("");
  const [usersNotifications, setUsersNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const auth = React.useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [typeMessage, setTypeMessage] = useState("success");
  const [contentMessage, setContentMessage] = useState("teste");
  const [classroomsName, setClassroomsName] = useState([]);

  const resetFields = () => {
    setClassrooms([]);
    setTitle("");
    setUsersNotifications([]);
    setSelectedImage("");
    setAnexosSelecionados([]);
    setLinksInseridos([]);
  };

  useEffect(() => {
    if (classrooms.length > 0 && !classrooms[0].hasOwnProperty("TurmaID")) {
      if (usersNotifications.length > 0) {
        setUsersNotifications([]);
      }
    }
  }, [classrooms]);

  useEffect(() => {
    getAllClassrooms().then((res) => {
      setClassrooms(res);
      setClassroomsName(res);
    });
  }, []);

  const getUsers = async () => {
    setLoading(true);
    if (classrooms[0] && classrooms[0].hasOwnProperty("TurmaID")) {
      const users = classrooms.map((item) => item.TurmaID);
      setUsersNotifications(await getStudentsAndResponsablesByClassroom(users));
      setClassrooms(users);
      setLoading(false);
    } else {
      setUsersNotifications(
        await getStudentsAndResponsablesByClassroom(classrooms)
      );
      setLoading(false);
    }
  };

  const sendFile = () => {
    const batchSize = 10;
    let currentIndex = 0;

    const sendBatch = () => {
      const batch = classrooms.slice(currentIndex, currentIndex + batchSize);
      currentIndex += batchSize;

      if (batch.length > 0) {
        batch.forEach((classroom) => {
          const className = classroomsName.find(
            (item) => item.TurmaID === classroom
          );
          const fData = new FormData();
          fData.append("announcement", selectedImage);
          // Adicionar anexos
          anexosSelecionados.forEach((file) => {
            fData.append("attachments", file);
          });
          // Adicionar links
          linksInseridos.forEach((link) => {
            fData.append("links", link);
          });

          fData.append("title", title);
          fData.append("classroom_id", classroom);
          fData.append("classroom_name", className.Nome);
          sendOneSignalAnnouncements(fData);
        });

        setTimeout(sendBatch, 1000);
      }
    };

    sendBatch();
    sendOneSignalNotifications({
      name: title,
      unit: auth.user.unit,
      ids: usersNotifications,
      message: "Novo comunicado do Colégio Autêntico!",
    });
    //TODO Verificar se deu certo os envios
    setContentMessage("Comunicados enviados com sucesso!");
    setTypeMessage("success");
    setShow(true);
    resetFields();
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    setAnexosSelecionados([...anexosSelecionados, ...files]);
  };

  const handleLinkInsert = () => {
    const link = prompt("Insira o link desejado:");
    if (link) {
      setLinksInseridos([...linksInseridos, link]);
    }
  };

  return (
    <Container>
      <Header />
      <IconsBar />
      {loading && <Loading />}
      <ContentArea>
        <SecretaryTabs />
      </ContentArea>
      <AlertMessages
        show={show}
        typeMessage={typeMessage}
        contentMessage={contentMessage}
        setShow={setShow}
      />
    </Container>
  );
};

export default Secretary;
