import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { IconButton, TextareaAutosize, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AttachIcon from "@mui/icons-material/AttachFile";
import LinkIcon from "@mui/icons-material/AddLink";
import styled from "styled-components";
import { Stack } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import { AuthContext } from "../contexts/Auth/AuthContext";
import { addActivity } from "../api/autenticoAPI";

const BoxTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const ContentBox = styled.div`
  width: 80%;
  margin: 0 auto;
`;
const InfoActivity = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const ActionsArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "90%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal({ open, setOpen, selected }) {
  const { user } = React.useContext(AuthContext);
  const [files, setFiles] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  const [title, setTitle] = React.useState();
  const [body, setBody] = React.useState();

  const clearStates = () => {
    setFiles([]);
    setDate(new Date());
    setTitle();
    setBody();
  };

  const createActivity = async () => {
    const { id: teacher_id } = user;
    const { id: classroom_id } = selected.classroom;
    const { id: subject_id } = selected.subject;
    const urls = null;
    const response = await addActivity({
      teacher_id,
      classroom_id,
      subject_id,
      date,
      title,
      body,
      urls,
    });
    if (response.data) {
      alert("Atividade cadastrada com sucesso!");
      clearStates();
      setOpen(false);
    } else {
      alert(
        "Erro ao cadastrar atividade, consulte o administrador do sistema!"
      );
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <BoxTitle>
              <IconButton
                onClick={() => {
                  setFiles([]);
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </BoxTitle>
            <InfoActivity>
              <h3>{selected.subject.name}</h3>
              <h3>{selected.classroom.name}</h3>
            </InfoActivity>
            <ContentBox>
              <TextField
                autoComplete="off"
                label="Título (Obrigatório)"
                variant="outlined"
                fullWidth
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <TextareaAutosize
                aria-label="minimum height"
                maxRows={10}
                minRows={10}
                placeholder="Detalhamento (Opcional)"
                value={body}
                onChange={(e) => {
                  setBody(e.target.value);
                }}
                style={{
                  resize: "none",
                  width: "100%",
                  marginTop: "10px",
                  padding: "10px",
                }}
              />
              <span>Anexos</span>
              <Box sx={{ p: 2, border: "1px dashed grey", height: 80 }}>
                <Stack
                  spacing={2}
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent={"center"}
                >
                  {files.length > 0 &&
                    Object.keys(files).map((key) => (
                      <span>{files[key].name}</span>
                    ))}
                </Stack>
              </Box>
              <Stack
                spacing={2}
                sx={{ width: "100%" }}
                direction="row"
                justifyContent={"center"}
              >
                <IconButton>
                  <input
                    onChange={({ target }) => setFiles(target.files)}
                    type="file"
                    id="files"
                    hidden
                    multiple
                  />
                  <label htmlFor="files">
                    <AttachIcon />
                  </label>
                </IconButton>
                <IconButton>
                  <LinkIcon />
                </IconButton>
              </Stack>
              <ActionsArea>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DatePicker
                    views={["day"]}
                    label="Selecione a data"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} />
                    )}
                  />
                </LocalizationProvider>
                <Button
                  onClick={() => createActivity(date)}
                  variant="contained"
                >
                  Criar Atividade
                </Button>
              </ActionsArea>
            </ContentBox>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
