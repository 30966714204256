import { Alert, Snackbar } from "@mui/material";
import React from "react";

export const CustomAlert = ({ infoType, infoMessage, status, setStatus }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setStatus(false);
  };

  return (
    <Snackbar
      open={status}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={infoType}
        sx={{ width: "100%" }}
      >
        {infoMessage}
      </Alert>
    </Snackbar>
  );
};
