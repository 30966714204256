import styled from "styled-components";

export const Container = styled.div``;
export const ContainerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const InputsArea = styled.div`
  max-width: 50%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
export const DataTitle = styled.span`
  color: #136905;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 10px auto;
`;
export const DataArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const DataGroup = styled.div``;
export const DataSubtitle = styled.span`
  color: #004d93;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;
export const DataLabel = styled.span`
  color: #868383;
  font-size: 15px;
  margin-right: 5px;
  text-align: center;
`;
export const DataValue = styled.span`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;
export const DocumentLink = styled.a`
  .link-drive {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    background-color: #f5f5f5;
    color: #4285f4; /* Cor do Google Drive */
    font-weight: bold;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    background-color: red;
  }

  .link-drive:hover {
    background-color: red;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .link-drive img {
    margin-right: 8px;
    width: 24px; /* Tamanho do ícone */
    height: 24px;
  }
`;

export const LinkArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  color: #4285f4;
`;
